/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}

.center {
    width: 1100px;
    margin: 0 auto;
}
.header {
    width: 100%;
}
.head_top {
    height: 115px;
    width: 100%;
    overflow: hidden;
    .logo {
        margin-top: 7.5px;
        width: 500px;
        img {
            height: 100px;
        }
    }
    .fr {
        width: 600px;
        padding-left: 100px;
        padding-top: 37px;
        .search {
            width: 280px;
            height: 36px;
            float: left;
            border: 1px solid #cdcdcd;
            border-radius: 20px;
            .btn_text {
                height: 34px;
                border: none;
                width: 240px;
                float: left;
                border-radius: 20px;
                padding-left: 10px;
                outline: 0;
            }
            .btn_sub {
                float: left;
                outline: 0;
                border: none;
                width: 36px;
                height: 34px;
                background: url(../img/sch.png) no-repeat center;
            }
        }
        .language {
            float: left;
            padding-left: 25px;
            padding-top: 5px;
            p {
                height: 26px;
                padding-left: 28px;
                background: url(../img/lang.png) no-repeat left center;
                span {
                    line-height: 26px;
                    display: block;
                    font-size: 14px;
                    color: #4a4a4a;
                    padding-right: 20px;
                    background: url(../img/jth.jpg) no-repeat right center;
                }
            }
            ul {
                display: none;
                li {
                    a {
                        display: block;
                        padding-left: 28px;
                        font-size: 14px;
                        color: #4a4a4a;
                        &:hover {
                            text-decoration: underline;
                            color: #007cd0;
                        }
                    }
                }
            }
        }
        .wx {
            float: right;
            padding-top: 5px;
            li {
                float: left;
                height: 26px;
                margin-left: 8px;
                img {}
            }
        }
    }
}
.nav {
    height: 60px;
    background: url(../img/nav.png) no-repeat center;
    ul {
        li {
            width: 110px;
            height: 59px;
            float: left;
            a {
                line-height: 59px;
                text-align: center;
                font-size: 16px;
                color: #fff;
                display: block;
                &:hover {
                    background: url(../img/navh.png) no-repeat center;
                }
                &.active {
                    background: url(../img/navh.png) no-repeat center;
                }
            }
        }
    }
}

.banner {
    width: 100%;
    height: 500px;
    overflow: hidden;
    position: relative;
    .slideBox {
        width: 1920px;
        height: 500px;
        position: relative;
        left: 50%;
        margin-left: -960px;
        position: relative;
        .prev {
            background: url(../img/le.png) no-repeat center;
            width: 65px;
            height: 123px;
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 10%;
            z-index: 100;
            font-size: 0;
            line-height: 0;
            text-indent: -999em;
            margin-top: -45px;
            cursor: pointer;
        }
        .next {
            background: url(../img/ri.png) no-repeat center;
            width: 65px;
            height: 123px;
            overflow: hidden;
            position: absolute;
            top: 50%;
            right: 10%;
            z-index: 100;
            font-size: 0;
            line-height: 0;
            text-indent: -999em;
            margin-top: -45px;
            cursor: pointer;

        }
        .hd {
            width: 100%;
            text-align: center;
            position: absolute;
            left: 0;
            bottom: 16px;
            overflow: hidden;
            height: 16px;
            z-index: 99;
            li {
                box-shadow: inset 1px 1px 1px #333;
                width: 12px;
                height: 12px;
                @include inline-block();
                overflow: hidden;
                margin: 0 3px;
                border-radius: 50%;
                overflow: hidden;
                cursor: pointer;
                background: #fff;
                &.on {
                    background: #125ed6;
                }
            }
        }
        .bd {
            position: relative;
            z-index: 9;
            li {
                img {
                    width: 1920px;
                    height: 500px;
                }
            }
        }
    }
}
.banner_text {
    position: absolute;
    width: 100%;
    padding-top: 120px;
    z-index: 99;
    top: 0;
    left: 0;
    .text {
        span {
            font-size: 41px;
            color: #fff;
            display: block;
            text-align: center;
            font-weight: bold;
        }
        strong {
            font-size: 50px;
            color: #fff;
            display: block;
            text-align: center;
            font-weight: bold;
        }
        p {
            color: #fff;
            text-align: center;
            font-size: 18px;
            font-family: arial;
            margin-top: 10px;
        }
        i {
            width: 104px;
            height: 5px;
            background: #fff;
            text-align: center;
            margin: 0 auto;
            display: block;
            font-style: normal;
            margin-top: 40px;
        }
    }
}
.hot_new {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
    .text {
        span {
            width: 85px;
            height: 85px;
            padding: 10px;
            background: #fe9e22;
            font-size: 26px;
            color: #fff;
            float: left;
            text-align: center;
            font-weight: bold;
        }
        .picScroll-top {
            height: 85px;
            width: 1015px;
            float: left;
            background: url(../img/hotnew.png);
            ul {
                li {
                    a {
                        font-size: 36px;
                        color: #1a1a1a;
                        line-height: 85px;
                        width: 100%;
                        padding-left: 20px;
                        display: block;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}

.main {
    width: 100%;
    overflow: hidden;
}

.i_box1 {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 45px;
}
.i_box1_content {
    .fl {
        width: 550px;
        height: 370px;
        img {}
    }
    .fr {
        width: 550px;
        padding-left: 40px;
        strong {
            font-size: 36px;
            color: #333;
            font-weight: bold;
            i {
                font-size: 16px;
                color: #666;
                font-style: normal;
                padding-left: 10px;
            }
        }
        span {
            width: 60px;
            height: 3px;
            background: #fe9e22;
            display: block;
            margin: 30px 0;
        }
        p {
            font-size: 14px;
            color: #343434;
            line-height: 26px;
        }
        a {
            margin-top: 45px;
        }
    }
}
a.more {
    width: 156px;
    height: 48px;
    font-size: 14px;
    color: #3f3f40;
    border: 1px solid #3f3f40;
    border-radius: 25px;
    display: block;
    text-align: center;
    line-height: 48px;
    letter-spacing: 2px;
}
.box_title {
    strong {
        font-size: 36px;
        color: #333;
        font-weight: bold;
        text-align: center;
        display: block;
    }
    span {
        font-size: 24px;
        color: #666;
        display: block;
        text-align: center;
        margin-top: 10px;
        background: url(../img/xian.png) no-repeat center;
    }
}
.i_box2 {
    background: #f2f2f2;
    padding: 50px 0;
}
.i_box2_content {
    .list {
        width: 100%;
        margin-top: 30px;
        ul {
            li {
                width: 350px;
                height: 480px;
                padding: 12px;
                float: left;
                background: #fff;
                margin-left: 25px;
                transition: .5s;
                &:first-child {
                    margin-left: 0;
                }
                &:hover {
                    background: #fe9e22;
                    transition: .5s;
                    span {
                        color: #fff;
                        transition: .5s;
                    }
                    p {
                        color: #fff;
                        transition: .5s;
                    }
                    .more {
                        transition: .5s;
                        border: 1px solid #fff;
                        color: #fff;
                    }
                }
                .imgauto {
                    width: 100%;
                    height: 140px;
                    overflow: hidden;
                }
                span {
                    font-size: 24px;
                    color: #080808;
                    display: block;
                    text-align: center;
                    padding: 0 20px;
                    margin-top: 20px;
                    transition: .5s;
                }
                p {
                    font-size: 14px;
                    color: #2d2d2d;
                    line-height: 26px;
                    margin-top: 20px;
                    padding: 0 23px;
                    transition: .5s;
                }
                .more {
                    margin: 0 auto;
                    margin-top: 20px;
                    transition: .5s;
                }
            }
        }
    }
}
.i_box3 {
    width: 100%;
    padding: 50px 0;
}
.i_box3_content {
    width: 100%;
    margin-top: 35px;
    .fl {
        width: 320px;
        p {
            font-size: 14px;
            color: #313131;
            line-height: 24px;
        }
        h3 {
            margin-top: 30px;
            font-size: 21px;
            display: block;
            color: #005d9a;
            padding-bottom: 12px;
            border-bottom: 1px solid #005d9a;
            margin-bottom: 30px;
        }
        .txtMarquee-top {
            overflow: hidden;
            position: relative;
        }
        .tempWrap {
            height: 186px;
            overflow: hidden;
        }
        ul {
            width: 320px;
            li {
                float: left;
                width: 320px;
                height: 31px;
                line-height: 31px;
                a {
                    span {
                        float: left;
                        font-size: 14px;
                        color: #2c2d2d;
                        padding-left: 20px;
                        background: url(../img/jt.png) no-repeat left center;
                    }
                    i {
                        float: right;
                        font-style: normal;
                        font-size: 14px;
                        color: #1e1e1e;
                    }
                }
            }
        }
        .more {
            background: #007cd0;
            color: #fff;
            border: none;
            margin-top: 40px;
        }
    }
    .fr {
        width: 750px;
        img {
            display: block;
            margin: 0 auto;
        }
    }
}

.i_box4_list {
    width: 100%;
    margin-top: 15px;
    overflow: hidden;
    ul {
        width: 103%;
        li {
            width: 260px;
            float: left;
            margin-right: 20px;
            margin-bottom: 20px;
            margin-top: 20px;
            .imgauto {
                width: 260px;
                height: 200px;
                overflow: hidden;
            }
            span {
                height: 40px;
                background: #0391cf;
                color: #fff;
                display: block;
                text-align: center;
                line-height: 40px;
                font-size: 16px;
            }
            p {
                font-size: 12px;
                color: #2b2b2b;
                line-height: 22px;
                margin-top: 12px;
            }
        }
    }
}

.i_box5 {
    width: 100%;
}
.i_box5_content {
    width: 100%;
    margin: 50px 0;
    .new_desc {
        width: 360px;
        height: 520px;
        background: url(../img/newb.png) no-repeat top left;
        .box_title {
            padding-top: 45px;
            padding-left: 5px;
            strong {
                color: #fff;
            }
            span {
                color: #fff;
                margin-top: 4px;
                background: none;
            }
        }
        p {
            font-size: 14px;
            color: #fff;
            padding: 0 33px;
            line-height: 26px;
            margin-top: 40px;
        }
        a {
            color: #fff;
            width: 62px;
            height: 62px;
            margin-left: 33px;
            margin-top: 50px;
            display: block;
            background: url(../img/a.png) no-repeat center;
        }
    }
    .fr {
        width: 710px;
    }
}
.flag_new {
    width: 100%;
    height: 180px;
    background: #f2f2f2;
    padding: 20px;
    .imgauto {
        width: 230px;
        height: 140px;
        float: left;
        overflow: hidden;
    }
    .text {
        padding-left: 25px;
        float: left;
        width: 440px;
        a {
            font-size: 18px;
            color: #007cd0;
            margin-top: 5px;
            display: block;
        }
        p {
            font-size: 12px;
            color: #007cd0;
            margin-top: 15px;
            line-height: 24px;
        }
    }
}
.new_list {
    width: 100%;
    margin-top: 8px;
    ul {
        li {
            width: 100%;
            float: left;
            margin-top: 30px;
            .time {
                width: 80px;
                height: 80px;
                float: left;
                background: url(../img/time.png) no-repeat center;
                span {
                    display: block;
                    text-align: center;
                    font-size: 36px;
                    color: #fff;
                }
                p {
                    font-size: 14px;
                    color: #fff;
                    text-align: center;
                }
            }
            .text {
                width: 610px;
                float: left;
                margin-left: 20px;
                a {
                    font-size: 21px;
                    color: #1e1e1e;
                    display: block;
                }
                p {
                    font-size: 12px;
                    margin-top: 8px;
                    color: #727171;
                    line-height: 22px;
                }
            }
        }
    }
}
.i_box6 {
    width: 100%;
    height: 588px;
    background: url(../img/hs.jpg) no-repeat top center;
}
.i_box6_content {
    width: 100%;
    padding-top: 70px;
    strong {
        font-size: 40px;
        display: block;
        text-align: center;
        color: #007cd0;
        font-weight: bold;
    }
    i {
        font-size: 15px;
        color: #007cd0;
        text-align: center;
        display: block;
        line-height: 30px;
        margin-top: 20px;
        font-style: normal;
    }
    .more {
        border: 1px solid #007cd0;
        color: #007cd0;
        margin: 0 auto;
        margin-top: 35px;
    }
    ul {
        width: 100%;
        text-align: center;
        li {
            width: 104px;
            height: 106px;
            @include inline-block();
            margin: 0 48px;
            background: url(../img/yuan.png) no-repeat center;
            position: relative;
            &.otop {
                top: 10px;
            }
            &.etop {
                top: 60px;
            }
            &:first-child {
                top: 75px;
            }
            span {
                text-align: center;
                display: block;
                color: #0077ce;
                font-size: 16px;
                padding-top: 15px;
            }
            p {
                font-size: 12px;
                color: #3773b9;
                padding: 0 10px;
                text-align: center;
                padding-top: 8px;
                line-height: 18px;
            }
        }
    }
}

.footer{
    background: #181617;
    padding: 45px 0;
    width: 100%;
    overflow: hidden;
}
.foot {
    .foot_tit {
        width: 100%;
        margin-bottom: 40px;
        p {
            font-size: 24px;
            color: #fff;
            padding-bottom: 8px;
            span {
                font-size: 14px;
                color: #fff;
                padding-left: 12px;
                margin-top: 8px;
            }
        }
        i {
            width: 75px;
            font-style: normal;
            height: 1px; 
            display: block;
            background: #3773b9;
        }
    }
    .message {
        width: 460px;
        padding-right: 90px;
        input {
            width: 370px;
            height: 38px;
            background: #3c3c3c;
            border: none;
            outline: 0;
            color: #fff;
            padding-left: 15px;
            line-height: 38px;
            display: block;
        }
        textarea {
            border: none;
            margin-top: 8px;
            outline: 0;
            width: 370px;
            max-width: 370px;
            max-height: 110px;
            height: 110px;
            color: #fff;
            padding: 15px;
            display: block;
            background: #3c3c3c;
        }
        .foot_btn {
            margin-top: 8px;
            background: #1e64bc;
        }
    }
    .foot_contact {
        width: 440px;
        padding-right: 115px;
        span {
            display: block;
            font-size: 12px;
            color: #ccc;
            line-height: 30px;
        }
    }
    .links {
        width: 200px;
        .foot_tit {
            margin-bottom: 34px;
        }
        ul {
            li {
                a {
                    display: block;
                    font-size: 12px;
                    color: #ccc;
                    line-height: 30px;
                }
            }
        }
    }
}
.copyright {
    width: 100%;
    border-top: 5px solid #1e64bc;
    padding-top: 22px;
    margin-top: 55px;
    p {
        font-size: 12px;
        color: #c8c8c8;
        text-align: left;
        a {
            font-size: 12px;
            color: #c8c8c8;
        }
    }
}






.page_banner {
    height: 200px;
    width: 100%;
    overflow: hidden;
    img {
        width: 1920px;
        height: 200px;
        position: relative;
        left: 50%;
        margin-left: -960px;
    }
}
.container {
    width: 100%;
    margin-bottom: 35px;
}
.main_l {
    width: 240px;
    margin-top: -50px;
    position: relative;
    z-index: 9;
    .box_title {
        width: 100%;
        background: #fff;
        padding-top: 30px;
        padding-bottom: 44px;
        strong {
            font-size: 32px;
            color: #000;
        }
        span {
            font-size: 18px;
            color: #090909;
            margin-top: 5px;
            background: url(../img/xian2.png) no-repeat center;
        }
    }
    #promenu {
        width: 100%;
        background: #f2f2f2;
        li {
            width: 240px;
            height: 49px;
            margin: 0 auto;
            margin-bottom: 3px;
            padding-bottom: 3px;
            border-bottom: 1px solid #eeefef;
            &.active {
                background: url(../img/pmenu.png) no-repeat center;
                a {
                    color: #fff;
                    background: url(../img/dian.png) no-repeat 8px center;
                }
                i {
                    background: url(../img/jiah.png) no-repeat 10px center;
                    &.on {
                        background: url(../img/adian.png) no-repeat 10px center;
                    }
                }
            }
            &:hover {
                background: url(../img/pmenu.png) no-repeat center;
                a {
                    color: #fff;
                    background: url(../img/dian.png) no-repeat 8px center;
                }
                i {
                    background: url(../img/jiah.png) no-repeat 10px center;
                    &.on {
                        background: url(../img/adian.png) no-repeat 10px center;
                    }
                }
            }
            a {
                height: 49px;
                line-height: 49px;
                display: block;
                color: #090909;
                font-size: 14px;
                width: 80%;
                float: left;
                text-align: left;
                padding-left: 40px;
                background: url(../img/dian1.png) no-repeat 8px center;
            }
            i {
                width: 20%;
                float: left;
                height: 49px;
                background: url(../img/jiah1.png) no-repeat 10px center;
                &.on {
                    background: url(../img/adian1.png) no-repeat 10px center;
                }
            }
        }
        .v_list {
            display: none;
            a {
                width: 224px;
                line-height: 30px;
                display: block;
                padding-left: 65px;
                &.active {
                    color: #1e64bc;
                }
            }
        }
    }
    .contact_f1 {
        margin-top: 20px;
        height: 399px;
        width: 240px;
        padding-top: 115px;
        background: url(../img/bg1.png) no-repeat left top;
        p {
            color: #fff;
            text-align: center;
            font-size: 23px;
            span {
                font-size: 29px;
                color: #fff;
            }
        }
        img {
            width: 88px;
            height: 88px;
            margin: 0 auto;
            display: block;
            position: relative;
            bottom: -94px;
        }
    }
    .contact_f2 {
        margin-top: 12px;
        background: url(../img/bg2.png) no-repeat left top;
        width: 240px;
        height: 183px;
        padding-top: 22px;
        p {
            font-size: 16px;
            color: #fff;
            text-align: center;
            margin-bottom: 15px;
        }
        img {
            margin: 0 auto;
            display: block;
            width: 108px;
            height: 108px;
        }
    }
}
.main_r {
    width: 825px;
    .page_title {
        width: 100%;
        border-bottom: 1px solid #eee;
        padding-top: 40px;
        padding-bottom: 8px;
        p {
            font-size: 26px;
            color: #1e64bc;
            span {
                font-size: 14px;
                color: #848484;
                padding-left: 10px;
            }
        }
    }
    .i_box4_list {
        li {
            margin-top: 0;
            margin-bottom: 40px;
        }
    }
}
.pro_list {
    margin-top: 60px;
    padding-left: 6px;
    border-left: 1px solid #eee;
}
.content_img {
    width: 100%;
    img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
}
.content {
    padding: 0 30px;
    p {
        font-size: 14px;
        color: #484848;
        line-height: 30px;
        margin-bottom: 20px;
    }
    img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
}
.pro_content {
    margin-left: 68px;
    border-top: 1px solid #eee;
    padding-top: 38px;
    margin-top: 38px;
    p {
        margin-bottom: 8px;
    }
}
.content_gues {
    margin-left: 68px;
    margin-top: 38px;
    i {
        font-size: 16px;
        color: #353535;
        font-weight: bold;
        font-style: normal;
        display: block;
        margin-bottom: 20px;
    }
    p {
        span {
            font-size: 14px;
            color: #353535;
            font-weight: bold;
            width: 75px;
            float: left;
            line-height: 38px;
            em {
              padding-left: 27px;  
            }
        }
        input {
            width: 525px;
            height: 38px;
            border: none;
            outline: 0;
            margin-bottom: 8px;
            padding-left: 8px;
            color: #080808;
            line-height: 38px;
            font-size: 14px;
            border: 1px solid #e6e6e6;
        }
        textarea {
            width: 525px;
            padding: 8px;
            height: 100px;
            border: none;
            outline: 0;
            border: 1px solid #e6e6e6;
            margin-bottom: 8px;
            max-width: 525px;
            max-height: 100px;
            font-size: 14px;
            color: #080808;
        }
        .cont_btn {
            margin-left: 75px;
            background: #017ecd;
            border: none;
            color: #fff;
        }
    }
}
.page_foot {
    .foot_tit {
        margin-bottom: 25px;
    }
    .foot_contact {
        width: 760px;
        span {

        }
    }
    .links {
        width: 260px;
        float: right;
        .foot_tit {
            text-align: center;
            i {
                margin: 0 auto;
            }
        }
        li {
            width: 50%;
            float: left;
            a {
                text-align: center;
            }
        }
    }
}

.hs_cont {
    ul {
        li {
            width: 100%;
            float: left;
            background: url(../img/shu.png) repeat-y 140px;
            &:hover {
                .fl {
                    span {
                        color: #feb822;
                        background: url(../img/d2.png) no-repeat 126px center;
                    }
                }
            }
            &.active {
                .fl {
                    span {
                        color: #feb822;
                        background: url(../img/d2.png) no-repeat 126px center;
                    }
                }
            }
            .fl {
                width: 170px;
                margin-top: 57px;
                span {
                    font-size: 24px;
                    color: #017bcf;
                    display: block;
                    padding-left: 30px;
                    background: url(../img/d1.png) no-repeat 126px center;
                }
            }
            .text {
                width: 625px;
                float: right;
                padding: 35px 0;
                border-bottom: 1px solid #eee;
                p {
                    font-size: 14px;
                    color: #575757;
                    line-height: 30px;
                    margin-bottom: 8px;
                }
            }
        }
    }
}
.parnet_cont {
    img {

    }
    .slideTxtBox {
        width: 752px;
        float: right;
        strong {
            font-size: 14px;
            color: #313131;
            display: block;
            margin: 20px 0;
        }
        .hd {
            width: 100%;
            height: 85px;
            background: url(../img/x3.png) repeat-x left  70px;
            li {
                width: 185px;
                height: 70px;
                float: left;
                margin-left: 4px;
                background: #efefef;
                cursor: pointer;
                font-size: 24px;
                color: #121212;
                text-align: center;
                line-height: 70px;
                &:first-child {
                    margin-left: 0;
                }
                &.on {
                    color: #fff;
                    padding-bottom: 15px;
                    height: 85px;
                    background: url(../img/lih.png) no-repeat left top;
                }
            }
        }
        .bd {
            h3 {
                font-size: 21px;
                color: #005d9a;
                display: block;
                padding-bottom: 10px;
                border-bottom: 1px solid #eee;
            }
            li {
                margin-top: 25px;
                padding-bottom: 25px;
                width: 100%;
                float: left;
                border-bottom: 1px solid #eee;
                .fl {
                    .imgauto {
                        width: 120px;
                        height: 120px;
                    }
                }
                .fr {
                    float: left;
                    margin-left: 25px;
                    a {
                        font-size: 18px;
                        color: #507fc6;
                        display: block;
                        margin-top: 15px;
                    }
                    span {
                        font-size: 16px;
                        color: #1e1e1e;
                        display: block;
                        margin: 10px 0;
                    }
                    p {
                        font-size: 16px;
                        color: #1e1e1e;
                    }
                }
            }
        }
    }
}

.art_list {
    padding-left: 60px;
    ul {
        li {
            border-bottom: 1px solid #eee;
            margin-top: 25px;
            padding-bottom: 25px;
            a {
                font-size: 21px;
                color: #0e0e0e;
                display: block;
                margin-bottom: 20px;
            }
            p {
                font-size: 14px;
                color: #828080;
                display: block;
                line-height: 26px;
            }
            span {
                display: block;
                font-size: 12px;
                color: #cfcfcf;
                margin-top: 8px;
            }
        }
    }
}

.ys_list {
    margin-left: 24px;
    ul {
        li {
            width: 100%;
            float: left;
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #eee;
            .fl {
                .imgauto {
                    width: 282px;
                    height: 180px;
                    overflow: hidden;
                }
            }
            .fr {
                float: left;
                width: 475px;
                margin-left: 35px;
                a {
                    font-size: 24px;
                    color: #323232;
                    display: block;
                }
                p {
                    font-size: 16px;
                    color: #747474;
                    line-height: 30px;
                    margin-top: 25px;
                }
            }
        }
    }
}

.ques_list {
    padding-left: 30px;
    li {
        &:first-child a {
            padding-bottom: 24px;
            line-height: 100%;
            background: url(../img/jth.png) no-repeat left 4px;
        }
        a {
            font-size: 16px;
            color: #040404;
            display: block;
            padding-left: 25px;
            border-bottom: 1px dashed #e6e6e6;
            line-height: 60px;
            background: url(../img/jth.png) no-repeat left center;
        }
    }
}

.content_tit {
    width: 100%;
    margin-left: 30px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    h3 {
        font-size: 26px;
        color: #1e64bc;
        display: block;
        text-align: center;
    }
    p {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        span {
            font-size: 14px;
            color: #666;
            padding-left: 15px;
        }
    }
}


/****************************** media ******************************/